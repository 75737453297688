import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export interface Parent {
  firstName: string,
  lastName: string,
  email: string[]
}
export interface GradeChildren {
  id: number,
  name: string,
  section: string,
  children: {
    firstName: string,
    lastName: string,
    parents: Parent[]
  }[]
}

const state: {grades: GradeChildren[]} = { grades: []};
function grades(){
  return state.grades.map(gc=>({id:gc.id,name:`${gc.name} ${gc.section}`}))
}


export default new Vuex.Store({
  state,
  getters: {
    grades
  },
  mutations: {
    SET_GRADES(state, payload:GradeChildren[]){
      state.grades = payload
    }
  },
  actions: {
    initGrades({commit},id){
      return axios.get('/children').then((res) => {
        if(res.data) {
          commit('SET_GRADES',res.data)
        }
      })
    }
  }
})
