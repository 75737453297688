









import { Component, Vue } from 'vue-property-decorator';
import Process from '@/components/Process.vue'; // @ is an alias to /src
import Grade from '@/components/Grade.vue'
@Component({
  components: {
    Process,
    Grade
  },
})
export default class Home extends Vue {}
