









































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Boletin } from "../excel";
import { flatMap } from "lodash-es";
import { GradeChildren, Parent } from "../store";
import { map as promiseMap } from "bluebird";
import axios from "axios";
import {formatNota } from "../util"

@Component
export default class TablaGrado extends Vue {
  @Prop({ default: [] }) readonly materias!: string[];
  @Prop({ default: [] }) readonly notas!: Boletin[];
  @Prop({ default: "" }) readonly grado!: string;

  enviados: string[] = [];
  nogrado: boolean = false;
  gradotxt: string = "";
  fecha: string = "";
  lapso: string = "";
  finalizado: boolean = false;
  enviando: boolean = false;

  getParents(boletin: Boletin) {
    const grados = this.$store.state.grades as GradeChildren[];
    const gr = grados.find(g => this.grado === `${g.name} ${g.section}`);
    if (!gr) {
      throw new Error("no grado");
    }
    const child = gr.children.find(
      c =>
        c.firstName.trim() === boletin.nombres.trim() &&
        c.lastName.trim() === boletin.apellidos.trim()
    );
    if (!child) {
      throw new Error("no student");
    }
    return child.parents;
  }

  get enviarDisabled(){
    return this.enviando || this.lapso.trim().length<3  || this.gradotxt.trim().length<3 || this.fecha.trim().length <3
  }

  enviar() {
    this.enviando = true
    const alumnos = this.notasAlumno
      .filter(al => !al.error)
      .filter(
        al => al.parents && al.parents.some(p => p.email && p.email.length != 0)
      )
    const requests:EmailRequest[] = alumnos.map((al):EmailRequest =>{
      return {
        nombre: `${al.boletin.nombres} ${al.boletin.apellidos}`,
        lapso: this.lapso,
        fecha: this.fecha,
        grado: this.gradotxt,
        emails: flatMap(al.parents, p => p.email ),
        materias: al.boletin.materias
      }
    });
    promiseMap(requests, req => {
      return axios.post("/email", req).then(val=>{
        this.enviados.push(req.nombre)
      })
    },{concurrency: 4}).then(()=>{
      this.finalizado = true
    }).finally(
      ()=>{
        this.enviando = false
      }
    )
  }

  volver(){
    this.enviados = [];
    this.nogrado = false;
    this.gradotxt = "";
    this.fecha = "";
    this.lapso  = "";
    this.enviando = false;
    this.finalizado = false; 
    this.$emit('volver')
  }

  get notasAlumno(): NotaAlumno[] {
    return this.notas.map(boletin => {
      let parents: Parent[] = [];
      let error;
      try {
        parents = this.getParents(boletin);
      } catch (err: any ) {
        error = err.message as string;
        if (err.message === "no grado") {
          this.nogrado = true;
        }
      }
      let ret: NotaAlumno = {
        data: [`${boletin.nombres} ${boletin.apellidos}`],
        parents,
        grado: this.grado,
        boletin,
        sent: this.enviados.indexOf(`${boletin.nombres} ${boletin.apellidos}`) !== -1
          ? true
          : false
      };
      if (error) {
        ret.error = error;
      }

      for (let i = 0; i < this.materias.length; i++) {
        const mat = this.materias[i];
        const nota = boletin.materias.find(m => m.materia === mat) || {nota: 0};
        ret.data.push(formatNota(nota.nota));
      }
      return ret;
    });
  }
}




type NotaAlumno = {
  data: string[];
  parents: Parent[];
  boletin: Boletin;
  grado: string;
  error?: string;
  sent?: boolean;
};
type EmailRequest = {
  emails: string[], 
  lapso: string,
  fecha: string,
  nombre: string,
  grado: string,
  materias: {
    materia: string,
    nota: string
  }[]
}
