import { CellValue } from 'exceljs';
import {sprintf} from 'sprintf-js';


export function formatNota(nota: number|string|CellValue) {
  if (typeof nota === 'number') {
    return formatNumericGrade(nota)
  } 
  if (typeof nota !== 'string') {
    return '*' 
  }
  nota = nota.replaceAll(/[^A-Fa-f*\.0-9]/gi,"")
  const notan = Number(nota)
  if(!isNaN(notan)){
    return formatNumericGrade(notan)
  }
  if(['A','B','C','D','E','F','*'].includes(nota.toUpperCase())){
    return nota.toUpperCase()
  }
  return '*'
}

function formatNumericGrade(n: number):string {
  n = Math.round(n);
  if(n<1 || n>20){
    return '*'
  }
  return sprintf('%02d', n)
}