
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex'
import {createExcel} from '@/excel'

@Component({
  computed: {
    ...mapState(['grades'])
  }
})
export default class Grade extends Vue {
  loading = false
  gradeid = 0
  
  created(){
    this.loading = true
    this.$store.dispatch('initGrades').finally(()=>{
    this.loading = false
    });
  }

  async generate(){
    const grade = (this as any).grades.find((v:any)=>v.id === this.gradeid)
    const buff = await createExcel(grade)
    const blob = new Blob([buff], {type: "application/vnd.ms-excel"});
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = `${grade.name} ${grade.section}.xlsx`
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
}
