import Excel from 'exceljs'
import { GradeChildren } from '@/store'
import {formatNota } from "./util"

export interface Materia {
  materia: string,
  nota: string
}

export interface Boletin {
  nombres: string,
  apellidos: string
  materias: Materia[]
}

export interface Grado {
  nombre: string,
  boletines: Boletin[]
}

export interface BoletinSheet {
  grado: string,
  materias: string[],
  boletines: Boletin[]
}

export async function createJSONFromBuffer(buff: ArrayBuffer): Promise<BoletinSheet> {
  const wbk = new Excel.Workbook()
  await wbk.xlsx.load(buff)
  const worksheet = wbk.worksheets[0]
  const grado = worksheet.getCell('A1').text
  const materias = getMaterias(worksheet)
  console.log(materias)
  return { boletines: getNotas(worksheet, materias), materias, grado }
}

function getMaterias(worksheet: Excel.Worksheet): string[] {
  const row = worksheet.getRow(2).values as string[];
  return Object.values(row).filter(v=>v!=='')
}

function getNotas(sht: Excel.Worksheet, nombresMaterias: string[]): Boletin[] {
  const boletines: Boletin[] = []
  sht.getColumn('A').eachCell((cell, num) => {
    if (num <= 2) {
      return
    }
    const [apellidos, nombres] = (cell.value as string).split(',')
    const row = sht.getRow(num)
    const materias: Materia[] = []
    for (let i = 2; i < (nombresMaterias.length + 2); i++) {
      const nota = formatNota(row.getCell(i).value)
      materias.push({ materia: nombresMaterias[i - 2], nota })
    }
    boletines.push({ nombres, apellidos, materias })
  })
  return boletines
}

export async function createExcel(grade: GradeChildren) {
  const wbk = new Excel.Workbook()
  const wst = wbk.addWorksheet('sheet1')
  let gradeName = `${grade.name} ${grade.section}`
  let childrens = grade.children.map(c => `${c.lastName}, ${c.firstName}`).sort((a, b) => a.localeCompare(b))
  wst.getColumn('A').values = [
    gradeName,
    '',
    ...childrens
  ]
  return wbk.xlsx.writeBuffer()
}