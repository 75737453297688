








import { Component, Prop, Vue } from 'vue-property-decorator';
import {createJSONFromBuffer, Boletin} from '@/excel'
import TablaGrado from '@/components/TablaGrado.vue'

const reader = new FileReader()
    

@Component({components:{
  TablaGrado
}})
export default class Process extends Vue {
  boletines:Boletin[] = []
  materias:string[] = []
  grado:string = ""

  resetTabla() {
    (this.$refs.fileuploadctl as any).value = ""
    this.boletines = []
    this.materias = []
    this.grado = ""
  }

  loadFile(ev: any) {
    const files = ev.target.files as FileList
    if(files.length === 0) {
      return
    }
    const self = this
    reader.addEventListener('load',function(ev: ProgressEvent){
      createJSONFromBuffer(this.result as ArrayBuffer).then((bolsheet)=>{
        self.boletines = bolsheet.boletines
        self.materias = bolsheet.materias
        self.grado = bolsheet.grado
      })
    })
    reader.readAsArrayBuffer(files[0])
  }

  
}
